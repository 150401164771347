var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-img", {
                attrs: {
                  height: "250px",
                  contain: "",
                  src: require("@/assets/images/404ErrorImage.png")
                }
              }),
              _c("p", { staticClass: "text-h5 text-center mt-4" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("layout.pageNotFound.title")))])
              ]),
              _c("p", { staticClass: "text-h5 text-center" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("layout.pageNotFound.message")) + " "
                ),
                _c("span", { staticClass: "blue--text" }, [
                  _vm._v(" support@vitruehealth.com")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }