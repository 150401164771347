<template>
  <v-container fluid fill-height>
    <v-row justify="center" no-gutters>
      <v-col cols="8">
        <v-img
          height="250px"
          contain
          src="@/assets/images/404ErrorImage.png"
        ></v-img>
        <p class="text-h5 text-center mt-4">
          <b>{{ $t("layout.pageNotFound.title") }}</b>
        </p>
        <p class="text-h5 text-center">
          {{ $t("layout.pageNotFound.message") }}
          <span class="blue--text"> support@vitruehealth.com</span>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>
